import { BsPersonFill } from 'react-icons/bs';
import styles from './styles.module.scss';

const Avatar = (props) => {
  const { avatar, size, placeholder } = props;

  return avatar ? (
    <div
      className={styles.avatar}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        backgroundImage: `url(${avatar})`,
      }}
    />
  ) : (
    <div className={styles.avatar}>
      <BsPersonFill size={80} />
    </div>
  );
};

export default Avatar;
