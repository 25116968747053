import React, { useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import {
  BsCurrencyDollar,
  BsFillLockFill,
  BsFillUnlockFill,
} from 'react-icons/bs';

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { useMapEvent } from 'react-leaflet/hooks';
import { Icon, divIcon } from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import SimpleButton from '../../ui/buttons/SimpleButton/SimpleButton';

import moment from 'moment';

import styles from './styles.module.scss';
import { toast } from '../../helpers/utils';
import { getEventsByArea } from '../../helpers/fetch';
import { CircularProgress } from '@mui/material';

const customMarkerDefault = new Icon({
  iconUrl: require('../../assets/imgs/map_marker_default.png'),
  iconSize: [38, 38],
});

const customMarkerNow = new Icon({
  iconUrl: require('../../assets/imgs/map_marker_now.png'),
  iconSize: [38, 38],
});

let waiter = null;
function MapHook(props) {
  const { onMapMove } = props;

  useEffect(() => {
    return () => {
      if (waiter) clearTimeout(waiter);
    };
  }, []);

  const map = useMapEvent('move', () => {
    if (waiter) clearTimeout(waiter);
    waiter = setTimeout(() => onMapMove(map.getBounds()), 1000);
  });
  return null;
}

const customClusterMarker = (cluster) =>
  new divIcon({
    html: `<div class=${styles.clusterMarker}>${cluster.getChildCount()}</div>`,
  });

const EventCard = (props) => {
  const { event, selected, onClick } = props;
  if (event.isEmpty) {
    return (
      <div key={event.id} className={`${styles.eventCard} ${styles.empty}`}>
        <span className={styles.name} />
        <div className={styles.dollar} />
        <div className={styles.eventTime} />
      </div>
    );
  }

  const startTime = moment(event.start_at).format('DD.MM HH:mm');
  const endTime = moment(event.end_at).format('DD.MM HH:mm');

  return (
    <div
      key={event.id}
      className={`${styles.eventCard} ${
        selected == event.id ? styles.selected : null
      } ${event.inTime ? styles.inTime : null}`}
      onClick={() => onClick(event)}
    >
      <span className={styles.name}>{event.name}</span>
      {event.privacy_type === 'PU' ? (
        <BsFillUnlockFill className={styles.privacy} />
      ) : (
        <BsFillLockFill className={styles.privacy} />
      )}
      {event.cost > 0 ? (
        <div className={styles.dollar}>
          <BsCurrencyDollar />
          <BsCurrencyDollar />
          <BsCurrencyDollar />
        </div>
      ) : (
        <div className={styles.free}>БЕСПЛАТНО</div>
      )}
      <div className={styles.eventTime}>
        {startTime} - {endTime}
      </div>
    </div>
  );
};

let eventsOffset = 0;

const Events = observer(() => {
  const [filterPrivacy, setFilterPrivacy] = useState(true);
  const [filterCost, setFilterCost] = useState(true);
  const [filterEmpty, setFilterEmpty] = useState(true);

  const [events, setEvents] = useState([]);
  const [totalEvents, setTotalEvents] = useState(0);
  const [myLocation, setMyLocation] = useState({ latitude: 0, longitude: 0 });
  const [searchBounds, setSearchBounds] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(-1);
  const [logoRemoved, setLogoRemoved] = useState(false);

  const mapRef = useRef(null);

  const navigate = useNavigate();

  const getCurrentLocation = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        successLocationHandler,
        errorLocationHandler
      );
    } else {
      alert('Местоположение недоступно');
    }
  };

  const successLocationHandler = (position) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    setMyLocation({
      latitude,
      longitude,
    });

    mapRef.current.setView([latitude, longitude], 15);
  };

  const errorLocationHandler = (error) => {
    if (mapRef.current) {
      mapRef.current.fitWorld();
    }

    switch (error.code) {
      case error.PERMISSION_DENIED:
        toast(
          'Невозможно узнать ваше местоположение, необходимо дать доступ.',
          'warn'
        );
        break;
      case error.POSITION_UNAVAILABLE:
        toast('Невозможно узнать ваше местоположение.', 'warn');
        break;
      case error.TIMEOUT:
        toast('Вышло время ответа о получении вашего местоположения.', 'warn');
        break;
      case error.UNKNOWN_ERROR:
        toast('Неизвестная ошибка о получении вашего местоположения.', 'error');
        break;
    }
  };

  const removeMapLogo = () => {
    const logo = document.querySelector('a[href="https://leafletjs.com"]');
    if (logo) {
      logo.nextElementSibling.remove();
      logo.remove();
      setLogoRemoved(true);
    } else setTimeout(removeMapLogo, 1000);
  };

  useEffect(() => {
    getCurrentLocation();
  }, []);

  useEffect(() => {
    if (mapRef.current) {
      const bounds = mapRef.current.getBounds();
      setSearchBounds(bounds);
      if (!logoRemoved) removeMapLogo();
    }
  }, [mapRef.current]);

  useEffect(() => {
    async function fetchEvents() {
      // _northEast - верх право
      // _southWest - низ лево

      // широта - горизонталь latitud
      // долгота - вертикаль longitude

      const area = {
        tl_coordinate_latitude: searchBounds._northEast.lat.clamp(-90, 90), // Координата широты верхней левой точки
        tl_coordinate_longitude: searchBounds._southWest.lng.clamp(-180, 180), // Координата долготы верхней левой точки
        br_coordinate_latitude: searchBounds._southWest.lat.clamp(-90, 90), // Координата широты нижней правой точки
        br_coordinate_longitude: searchBounds._northEast.lng.clamp(-180, 180), // Координата долготы нижней правой точки
      };

      async function getEvents(area, newBounds) {
        const result = await getEventsByArea(area, 'location', eventsOffset);
        if (result) {
          if (newBounds) {
            setTotalEvents(result.total_count);
          }
          const newEvents = newBounds
            ? result.list
            : [...events, ...result.list];
          setEvents(newEvents);

          if (result.total_count > 100 * (eventsOffset + 1)) {
            eventsOffset++;
            await getEvents(area, false);
          }
        }
      }
      eventsOffset = 0;
      await getEvents(area, true);
    }

    if (searchBounds) fetchEvents();
  }, [searchBounds]);

  const onMarkerClick = (eventId) => {
    setSelectedEvent(eventId);
  };

  const onEventFirstClick = (event) => {
    if (selectedEvent == event.id) {
      mapRef.current.stop();
      onEventOpen(event.id);
    } else {
      setSelectedEvent(event.id);

      mapRef.current.flyTo(
        [
          event.location.coordinate_latitude || myLocation.latitude,
          event.location.coordinate_longitude || myLocation.longitude,
        ],
        15
      );
    }
  };

  const onMapMoveHandler = (mapBounds) => {
    setSearchBounds(mapBounds);
  };

  const onEventOpen = (eventId) => {
    navigate(`/event/${eventId}`);
  };

  const switchPrivacyFilter = (event) => {
    setFilterPrivacy(event.target.checked);
  };

  const switchCostFilter = (event) => {
    setFilterCost(event.target.checked);
  };

  const switchEmptyFilter = (event) => {
    setFilterEmpty(event.target.checked);
  };

  const preparedEvents = useMemo(
    () =>
      events.map((event) => {
        const startTime = moment(event.start_at).valueOf();
        const endTime = moment(event.end_at).valueOf();
        const nowTime = moment().valueOf();

        let inTime = false;
        if (startTime < nowTime && nowTime < endTime) {
          inTime = true;
        }

        return { ...event, inTime };
      }),
    [events]
  );

  const eventItems = useMemo(() => {
    const items = [];
    for (let i = 0; i < totalEvents; i++) {
      if (i < preparedEvents.length) {
        items.push(
          <EventCard
            key={preparedEvents[i].id}
            event={preparedEvents[i]}
            selected={selectedEvent}
            onClick={onEventFirstClick}
          />
        );
      } else {
        items.push(
          <EventCard
            key={`empty_event-${i}`}
            event={{
              isEmpty: true,
              id: `empty_event-${0}`,
            }}
          />
        );
      }
    }

    return items;
  }, [preparedEvents, totalEvents, selectedEvent]);

  return (
    <div className={styles.events}>
      <div className={styles.mapContainer}>
        <MapContainer
          ref={mapRef}
          center={[myLocation.latitude, myLocation.longitude]}
          zoom={15}
          scrollWheelZoom={true}
          style={{ height: '100%', width: '100%' }}
        >
          <MapHook onMapMove={onMapMoveHandler} />
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <MarkerClusterGroup
            chunkedLoading
            iconCreateFunction={(cluster) => customClusterMarker(cluster)}
          >
            {preparedEvents.map((event) => (
              <Marker
                key={event.id}
                position={[
                  event.location.coordinate_latitude,
                  event.location.coordinate_longitude,
                ]}
                icon={event.inTime ? customMarkerNow : customMarkerDefault}
                eventHandlers={{
                  click: () => {
                    onMarkerClick(event.id);
                  },
                }}
              >
                <Popup>
                  <div>{event.name}</div>
                  <div>{moment(event.start_at).format('MM.D h:mm')}</div>
                  <SimpleButton
                    text="Открыть"
                    classes={styles.popupMarkerOpenBtn}
                    onClick={() => onEventOpen(event.id)}
                  />
                </Popup>
              </Marker>
            ))}
          </MarkerClusterGroup>
          {/* {myLocation && (
            <Circle
              center={[myLocation.latitude, myLocation.longitude]}
              radius={searchRadius}
            />
          )} */}
        </MapContainer>
        {!logoRemoved && (
          <div className={styles.mapLoader}>
            <CircularProgress />
          </div>
        )}
      </div>
      <div className={styles.eventsContainer}>
        <div className={styles.filters}>
          <FormControlLabel
            control={
              <Switch
                disabled
                checked={filterPrivacy}
                onChange={switchPrivacyFilter}
              />
            }
            label="приватные"
          />
          <FormControlLabel
            control={
              <Switch
                disabled
                checked={filterCost}
                onChange={switchCostFilter}
              />
            }
            label="платные"
          />
          <FormControlLabel
            control={
              <Switch
                disabled
                checked={filterEmpty}
                onChange={switchEmptyFilter}
              />
            }
            label="свободные"
          />
        </div>
        <div className={styles.eventsList}>{eventItems}</div>
      </div>
    </div>
  );
});

export default Events;
