import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import './helpers/proto';
import './css/fonts.css';
import './css/index.css';
import './css/reset.css';
import './css/variables.scss';
import 'leaflet/dist/leaflet.css';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

function render() {
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  );
}

if (window.location.hash.includes('#tgWebAppData')) {
  import('./helpers/tgWebApp').then((result) => render());
} else render();
