import React from 'react';

import styles from './styles.module.scss';

export default function About() {
  return (
    <div className={styles.about}>
      <div className={styles.aboutCard}>
        <h1 className={styles.title}>Мероприятник</h1>
        <div className={styles.description}>
          <p>Лучший ивент сервис рунета!</p>
          <p>
            Создавай мероприятия на любую тематику и приглашай людей участвовать
            в них!
          </p>
          <p>
            Будь то день рождение или официальная встреча, с помощью нашего
            сервиса вы всегда легко и просто сможете огранизовать любое
            мероприятие.
          </p>
        </div>
      </div>
      <div className={styles.contactsCard}>
        <h1 className={styles.title}>Контакты</h1>
        <div className={styles.description}>
          <p>
            <strong>email: </strong>
            <a href="mailto:meropriatnik@mail.ru">meropriatnik@mail.ru</a>
          </p>
          <p>
            <strong>telegram: </strong>
            <a href="https://web.telegram.org/k/#@meropriatnik" target="_blank">
              web.telegram.org/k/#@meropriatnik
            </a>
          </p>
          <p>
            <strong>vk: </strong>
            <a href="https://vk.com/meropriatnik" target="_blank">
              vk.com/meropriatnik
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
