import TelegramSvg from '../../../assets/imgs/share/telegram.svg';
import VkSvg from '../../../assets/imgs/share/vk.svg';
import MailSvg from '../../../assets/imgs/share/mail.svg';
import WhatsAppSvg from '../../../assets/imgs/share/whatsapp.svg';

import styles from './styles.module.scss';

export default function Share(props) {
  const { shareUrl, shareText } = props;

  const width = Math.min(window.innerWidth - 50, 600);
  const height = Math.min(window.innerHeight - 100, 500);
  const left = window.innerWidth / 2 - 325;

  const options = `status=0,toolbar=0,width=${width},height=${height},top=100,left=${left}`;

  return (
    <div className={styles.share}>
      <div
        onClick={() =>
          window.open(
            `https://telegram.me/share/url?url=${shareUrl}&text=${shareText}`,
            'sharer',
            options
          )
        }
        className={styles.btn}
        style={{ backgroundImage: `url(${TelegramSvg})` }}
        title="Поделиться в Telegram"
      />
      <div
        onClick={() =>
          window.open(
            `https://vk.com/share.php?url=${shareUrl}`,
            'sharer',
            options
          )
        }
        className={styles.btn}
        style={{ backgroundImage: `url(${VkSvg})` }}
        title="Поделиться в VK"
      />
      <div
        onClick={() =>
          window.open(
            `https://connect.mail.ru/share?url=${shareUrl}&title=${shareText}`,
            'sharer',
            options
          )
        }
        className={styles.btn}
        style={{ backgroundImage: `url(${MailSvg})` }}
        title="Поделиться в @Мой мир"
      />
      <div
        onClick={() =>
          window.open(
            `https://web.whatsapp.com/send?text=${shareText}%20-%20${shareUrl}`,
            'sharer',
            options
          )
        }
        className={styles.btn}
        style={{ backgroundImage: `url(${WhatsAppSvg})` }}
        title="Поделиться в WhatsApp"
      />
    </div>
  );
}
