import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../store/index';

import {
  BsSliders,
  BsDoorOpenFill,
  BsFillPersonFill,
  BsInfoSquare,
  BsFillMenuButtonWideFill,
} from 'react-icons/bs';

import styles from './styles.module.scss';

import MuiMenu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';

const Menu = observer(() => {
  const authStore = useAuthStore();
  const navigate = useNavigate();

  const logout = async () => {
    const success = await authStore.logout();
    if (success) navigate('/');
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSettings = () => {
    handleClose();
    navigate('/settings');
  };

  const handleAbout = () => {
    handleClose();
    navigate('/about');
  };

  return (
    <>
      <BsFillMenuButtonWideFill
        title="меню"
        className={styles.menuBtn}
        onClick={handleClick}
        size={24}
      />
      <MuiMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleSettings}>
          <BsSliders style={{ marginRight: '5px' }} /> Настройки
        </MenuItem>
        <MenuItem onClick={handleAbout}>
          <BsInfoSquare style={{ marginRight: '5px' }} /> О нас
        </MenuItem>
        <Divider />
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <BsDoorOpenFill />
          </ListItemIcon>
          Выйти
        </MenuItem>
      </MuiMenu>
    </>
  );
});

export default Menu;
