import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useProfileStore } from '../../store/index';

import styles from './styles.module.scss';
import ProfileModal from '../../components/profileModal';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { BsChevronDown } from 'react-icons/bs';

import Loader from '../../ui/components/loader';
import SimpleButton from '../../ui/buttons/SimpleButton/SimpleButton';
import { Divider, TextField } from '@mui/material';

const Settings = observer(() => {
  const [expanded, setExpanded] = useState(0);
  const [profileHasChanged, setProfileHasChanged] = useState(false);

  const profileStore = useProfileStore();

  const [firstName, setFirstName] = useState(profileStore.firstName);
  const [lastName, setLastName] = useState(profileStore.lastName);
  const [middleName, setMiddleName] = useState(profileStore.middleName);

  useMemo(() => {
    let changed = false;
    changed =
      profileStore.firstName !== firstName ||
      profileStore.lastName !== lastName ||
      profileStore.middleName !== middleName;

    setProfileHasChanged(changed);
  }, [firstName, lastName, middleName]);

  const onFirstNameChange = (e) => {
    const value = e.target.value;
    setFirstName(value);
  };

  const onLastNameChange = (e) => {
    const value = e.target.value;
    setLastName(value);
  };

  const onMiddleNameChange = (e) => {
    const value = e.target.value;
    setMiddleName(value);
  };

  const saveProfileHandler = () => {
    setLoading(true);
    const result = profileStore.saveProfile({
      first_name: firstName,
      last_name: lastName,
      middle_name: middleName,
    });
    if (result) {
      setProfileHasChanged(false);
    }
    setLoading(false);
  };

  const handleAccordion = (index) => (event, isExpanded) => {
    setExpanded(isExpanded ? index : -1);
  };

  const [loading, setLoading] = useState(false);

  if (loading) {
    return <Loader />;
  }
  return (
    <div className={styles.settings}>
      {loading && <Loader />}
      <Accordion expanded={expanded == 0} onChange={handleAccordion(0)}>
        <AccordionSummary
          className={styles.accordionTitle}
          expandIcon={<BsChevronDown />}
        >
          Настройки профиля
        </AccordionSummary>
        <AccordionDetails className={styles.accordionDetails}>
          <TextField
            label="Имя"
            variant="outlined"
            type="text"
            value={firstName}
            onInput={onFirstNameChange}
          />

          <TextField
            label="Фамилия"
            variant="outlined"
            type="text"
            value={lastName}
            onInput={onLastNameChange}
          />
          <TextField
            label="Отчество"
            variant="outlined"
            type="text"
            value={middleName}
            onInput={onMiddleNameChange}
          />
          <Divider />
          <SimpleButton
            text="Сохранить изменения"
            disabled={!profileHasChanged}
            onClick={saveProfileHandler}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        disabled
        expanded={expanded == 1}
        onChange={handleAccordion(1)}
      >
        <AccordionSummary
          className={styles.accordionTitle}
          expandIcon={<BsChevronDown />}
        >
          Смена пароля
        </AccordionSummary>
        <AccordionDetails className={styles.accordionDetails}>
          <TextField label="Прежний пароль" variant="outlined" type="text" />
          <TextField label="Новый пароль" variant="outlined" type="text" />
          <TextField
            label="Повторите новый пароль"
            variant="outlined"
            type="text"
          />
          <Divider />
          <SimpleButton text="Сменить пароль" />
        </AccordionDetails>
      </Accordion>
      <Accordion
        disabled
        expanded={expanded == 1}
        onChange={handleAccordion(1)}
      >
        <AccordionSummary
          className={styles.accordionTitle}
          expandIcon={<BsChevronDown />}
        >
          Настройки приватности
        </AccordionSummary>
        <AccordionDetails></AccordionDetails>
      </Accordion>
    </div>
  );
});

export default Settings;
